$(window).on("scroll", function() {
  if($(window).scrollTop() > 50) {
      $(".navbar").addClass("active");
  } else {
      //remove the background property so it comes transparent again (defined in your css)
     $(".navbar").removeClass("active");
  }
});
$(document).ready(function(){
  $('#sidemenu-toggle').click(function(){
    $(this).toggleClass('open');
    $('body').toggleClass('sidemenu-open');
  });
  $('#close-toggle').click(function(){
    window.location = "landing.html";
  });
  $('#close-toggle-results').click(function(){
    window.location = "results.html";
  });
  $('#color-picker').click(function(){
    window.location = "landing.html";
  });

});
